/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useEffect } from 'react';
import Layout from '../components/layout';
import * as queryString from 'query-string';
import { isBrowser } from '../utils';
import Spinner from '../components/Spinner';
import { navigateIfWhiteListed } from '../state/session';
import * as auth from '../utils/auth';
import * as analytics from '../utils/analytics';

const LogoutPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const search = isBrowser ? window.location.search : '';
  const { redirect }  = queryString.parse(search);
  useEffect(() => {
    auth.logout();
    setTimeout(() => navigateIfWhiteListed(new URL(redirect)), 1); // cookie removal require some time
  }, [redirect]);

  return (
    <Layout paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Box sx={{ position: 'relative', minHeight: 400 }}>
        <Spinner size="medium" />
      </Box>
    </Layout>
  );
};

export default LogoutPage;
